import React from "react";
import Style from "../Style/Style";
import { Link } from "react-router-dom";


const Footer = () => {

    const redirectClickLink = () => {
        
    }

    return(
        <>
            <footer style={Style.footer}>
                <h2 
                    style={{
                        display: "block",color: "white",textAlign: "center",
                        fontSize: "15px", letterSpacing: "0.7em",wordSpacing:"0.1em",
                        marginBottom: "25px"
                    }}
                >
                    SERVICE CLIENTS
                </h2>

                <div 
                    style={{
                        display: "grid",gridTemplateColumns: "1fr 1fr 1fr",
                        gridTemplateRows: "70px 70px",alignItems: "center",
                        justifyItems: "center",
                        gridTemplateAreas: "--var('ch1 ch2 ch3' 'ch4 ch5 ch6')",
                        marginBottom: "25px",
                    }}
                >
                    <div
                        style={{gridArea: "--var('ch1')",display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Link
                            to={""}
                            target="_blank"
                            style={{textDecoration: "none",color: "white"}}
                        >
                            FACEBOOK
                        </Link>
                        <span style={{width: "100%",height: "0.95px",backgroundColor:"blue"}}></span>
                    </div>
                    <div
                        style={{gridArea: "--var('ch2')",display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Link
                            to={""}
                            onClick={redirectClickLink}
                            target="_blank"
                            style={{textDecoration: "none",color: "white"}}
                        >
                            INSTAGRAM
                        </Link>
                        <span style={{width: "100%",height: "0.95px",backgroundColor:"purple"}}></span>
                    </div>
                    <div
                        style={{gridArea: "--var('ch3')",display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Link
                            to={``}
                            onClick={redirectClickLink}
                            target="_blank"
                            style={{textDecoration: "none",color: "white"}}
                        >
                            WHATSAPP
                        </Link>
                        <span style={{width: "100%",height: "0.95px",backgroundColor:"green"}}></span>
                    </div>
                    <div
                        style={{gridArea: "--var('ch4')",display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Link
                            to={""}
                            onClick={redirectClickLink}
                            target="_blank"
                            style={{textDecoration: "none",color: "white"}}
                        >
                            E-MAIL
                        </Link>
                        <span style={{width: "100%",height: "0.95px",backgroundColor:"red"}}></span>
                    </div>
                    <div
                        style={{gridArea: "--var('ch5')",display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Link
                            to={""}
                            onClick={redirectClickLink}
                            target="_blank"
                            style={{textDecoration: "none",color: "white"}}
                        >
                            TELEPHONE
                        </Link>
                        <span style={{width: "100%",height: "0.95px",backgroundColor: "#53b7df"}}></span>
                    </div>
                    <div
                        style={{gridArea: "--var('ch6')",display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Link
                            to={""}
                            onClick={redirectClickLink}
                            target="_blank"
                            style={{textDecoration: "none",color: "white"}}
                        >
                            ADRESSE
                        </Link>
                        <span style={{width: "100%",height: "0.95px",backgroundColor:"yellow"}}></span>
                    </div>
                </div>

                <h2 
                    style={{
                        display: "block",color: "white",textAlign: "center",
                        fontSize: "15px", letterSpacing: "0.3em",wordSpacing:"0.1em",
                        marginBottom: "50px",
                    }}
                >
                    CRÉONS ENSEMBLE LA LÉGENDE QUI VOUS EST DUE DE DROIT
                </h2>
            </footer>
        </>
    );
}

export default Footer;