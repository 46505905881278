import React from "react";


const List = ({
    list1, list2, list3, list4, list5,list15,
    list6, list7,list8,rule,style
}) => {
    return (
        <>
            {
                (rule == "list1") &&

                <ul>
                    <li style={style}>{list1}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li style={style}>{list7}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li style={style}>{list8}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li style={style}>{list6}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <li style={style}>{list4}</li>&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <li style={style}>{list5}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li style={style}>{list2}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <li style={style}>{list3}</li>&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <li style={style}>{list15}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                </ul>
            }
            
        </>
    );
}

export default List;