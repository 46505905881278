const ProgressBar = ({ form, identify}) => {

    return (
        <>
            <div
                className={form}
                id={identify}
            >
            </div>
            {/* <progress
                value={stateProgress}
                max={maximale}
                className={form}
                id={identify}
            /> */}
        </>
    )

}

export default ProgressBar