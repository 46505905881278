import React from "react";
import Content from "../Layouts/Content";
import Template from "../Layouts/Template";


const Arkos = () => {

    const title = document.querySelector('#royal-sale-page-title')
    title.textContent = "ARKOS | RS Production"
    let context = ""

    return (
        <>
            <Template rule={"form-header-a"}>

                <Content rule={"arkos"}></Content>

            </Template>
        </>
    );
}

export default Arkos;