import React from "react";
import Content from "../Layouts/Content";
import Template from "../Layouts/Template";


const Histoire = () => {

    const title = document.querySelector('#royal-sale-page-title')
    title.textContent = "Histoire | RS Production"
    let context = "Des films des séries des animés et tout le cinéma sur Royal Sale Production"

    return (
        <>
            <Template rule={"form-header-a"}>

                <Content rule={"histoire"}></Content>

            </Template>
        </>
    );
}

export default Histoire;