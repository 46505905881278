import Arkos from './Royal FR/Pages/Arkos';
import AvisAuxClients from './Royal FR/Pages/AvisAuxClients';
import RsCom from './Royal FR/Pages/RsCom';
import RsHcm from './Royal FR/Pages/RsHcm';
import RsMusic from './Royal FR/Pages/RsMusic';
import RsProduction from './Royal FR/Pages/RsProduction';
import "./Royal FR/Style/style.css"
import Histoire from './Royal FR/Pages/Histoire';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';

const Router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to={'/royal-sale-production'}/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production",
        element: <RsProduction/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/rs-hcm",
        element: <RsHcm/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/rs-com",
        element: <RsCom/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/rs-music",
        element: <RsMusic/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/arkos",
        element: <Arkos/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/histoire",
        element: <Histoire/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/avis-aux-clients",
        element: <AvisAuxClients/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "*",
        element: <NotFoundPage/>
    }
])

export default Router