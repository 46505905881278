import React, { useState } from "react";
import { Link } from "react-router-dom";
import Style from "../Style/Style";
import List from "./List";


const Header = ({
    rule,
}) => {


    
    const [myStyle, setMyStyle] = useState({
        myStyle1: Style.linkStyle1,
        myStyle2: Style.linkStyle1,
        myStyle3: Style.linkStyle1,
        myStyle9: Style.linkStyle1,
        myStyle10: Style.linkStyle1,
        myStyle12: Style.linkStyle1,
        myStyle15: Style.linkStyle1,
        myStyle4: Style.header,
        myStyle5: Style.headerChild1,
        myStyle6: Style.headerChild2,
        myStyle7: Style.headerChild3,
        myStyle8: Style.listStyle1,
        myStyle13: Style.linkStyle1,
        myStyle14: Style.linkStyle1,
    })

    const [timeOutId,setTimeOutId] = useState(null)

    const headerAnim = () => {

        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setMyStyle({
                    ...myStyle,
                    myStyle4: {
                        display: "none",opacity: "0.5",
                    }
                })
            }
            else{
                setMyStyle({
                    ...myStyle,
                    myStyle4: Style.header
                })
            }

            if (window.scrollTo > 0) {
                setMyStyle({
                    ...myStyle,
                    myStyle4: Style.header
                })
            }
        })
    }
    headerAnim()

    // const clickLink = (e) => {
    //     e.preventDefault()
    //     var id = document.querySelectorAll('.click')
    //     id[0].classList.add('.click-color')
    //     id[1].classList.add('.click-color')
    //     id[2].classList.add('.click-color')
    //     id[3].classList.add('.click-color')
    //     id[4].classList.add('.click-color')
    //     id[5].classList.add('.click-color')
    //     id[6].classList.add('.click-color')
    // }

    const hoverLink1 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle1: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink1 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle1: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }
    const hoverLink2 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle2: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink2 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle2: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }
    const hoverLink3 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle3: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink3 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle3: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }

    const hoverLink9 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle9: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink9 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle9: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }

    const hoverLink10 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle10: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink10 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle10: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }

    const hoverLink14 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle14: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink14 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle14: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }

    const hoverLink13 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle13: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink13 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle13: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }

    const hoverLink12 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle12: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink12 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle12: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }

    const hoverLink15 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle15: { color: "orangered", textDecoration: "none" }
        })
    }

    const outLink15 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle15: { color: "white", textDecoration: "none" }
            })
        }, 0))
    }


    return(
        <>
            {
                rule == "form-header-a" ?

                <header style={myStyle.myStyle4}>
                    <div style={myStyle.myStyle5}>
                        <List
                            style={myStyle.myStyle8}
                            rule={"list1"}
                            // list1={
                            //     <>
                            //         <Link
                            //             to={"/"}
                            //             style={myStyle.myStyle1}
                            //             onMouseOver={hoverLink1}
                            //             onMouseOut={outLink1}
                            //             // onClick={clickLink}
                            //             // className="click"
                            //         >
                            //             <strong>ACCUEIL</strong>
                            //         </Link>
                            //     </>
                            // }
                            list1={
                                <>
                                    <Link
                                        to={"/royal-sale-production"}
                                        style={myStyle.myStyle1}
                                        onMouseOver={hoverLink1}
                                        onMouseOut={outLink1}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>ACCUEIL</strong>
                                    </Link>
                                </>
                            }
                            list7={
                                <>
                                    <Link
                                        to={"/royal-sale-production/rs-hcm"}
                                        style={myStyle.myStyle14}
                                        onMouseOver={hoverLink14}
                                        onMouseOut={outLink14}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>RS HCM</strong>
                                    </Link>
                                </>
                            }
                            // list4={
                            //     <>
                            //         <Link
                            //             to={"/royal-sale-production"}
                            //             style={myStyle.myStyle9}
                            //             onMouseOver={hoverLink9}
                            //             onMouseOut={outLink9}
                            //             // onClick={clickLink}
                            //             // className="click"
                            //         >
                            //             <strong>ROYAL SALE PRODUCTION</strong>
                            //         </Link>
                            //     </>
                            // }
                            list6={
                                <>
                                    <Link
                                        to={"/royal-sale-production/rs-music"}
                                        style={myStyle.myStyle13}
                                        onMouseOver={hoverLink13}
                                        onMouseOut={outLink13}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>RS MUSIC</strong>
                                    </Link>
                                </>
                            }
                            list8={
                                <>
                                    <Link
                                        to={"/royal-sale-production/rs-com"}
                                        style={myStyle.myStyle12}
                                        onMouseOver={hoverLink12}
                                        onMouseOut={outLink12}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>RS COM</strong>
                                    </Link>
                                </>
                            }
                            list15={
                                <>
                                    <Link
                                        to={"/royal-sale-production/avis-aux-clients"}
                                        style={myStyle.myStyle15}
                                        onMouseOver={hoverLink15}
                                        onMouseOut={outLink15}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>AVIS CLIENTS</strong>
                                    </Link>
                                </>
                            }
                            list5={
                                <>
                                    <Link
                                        to={"/royal-sale-production/arkos"}
                                        style={myStyle.myStyle10}
                                        onMouseOver={hoverLink10}
                                        onMouseOut={outLink10}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>ARKOS</strong>
                                    </Link>
                                </>
                            }
                            list2={
                                <>
                                    <Link
                                        to={"/royal-sale-production/histoire"}
                                        style={myStyle.myStyle2}
                                        onMouseOver={hoverLink2}
                                        onMouseOut={outLink2}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>HISTOIRE</strong>
                                    </Link>
                                </>
                            }
                            // list2={
                            //     <>
                            //         <Link
                            //             to={"/royal-sale-production/stream-buy"}
                            //             style={myStyle.myStyle2}
                            //             onMouseOver={hoverLink2}
                            //             onMouseOut={outLink2}
                            //             // onClick={clickLink}
                            //             // className="click"
                            //         >
                            //             <strong>SERVICES</strong>
                            //         </Link>
                            //     </>
                            // }
                            // list3={
                            //     <>
                            //         <Link
                            //             to={"/royal-sale-production/a-propos"}
                            //             style={myStyle.myStyle3}
                            //             onMouseOver={hoverLink3}
                            //             onMouseOut={outLink3}
                            //             // onClick={clickLink}
                            //             // className="click"
                            //         >
                            //             <strong>A PROPOS</strong>
                            //         </Link>
                            //     </>
                            // }
                        >
                        </List>
                    </div>
                    {/* <div style={myStyle.myStyle6}></div>
                    <div style={myStyle.myStyle7}></div> */}
                </header> :

                ""
            }
        </>
    );
}

export default Header;