import React from "react";
import { Link, useNavigate } from "react-router-dom";


const LinkRoyal = ({
    rule, children, link, scrButton, lang
}) => {

    const navigation = new useNavigate();

    const redirectLink = (direction) => {
        navigation(direction);
    }

    return (
        <>
            {
                (rule == "link1") &&

                <a href=""
                    onClick={redirectLink}
                >
                    {children}
                </a>
            }

            {
                rule == 'scrButton' &&

                <Link
                    to={link}
                    style={lang}
                    onClick={scrButton}
                >
                    {children}
                </Link>
            }

        </>
    );
}

export default LinkRoyal;