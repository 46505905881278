import React, { useState } from "react";
import { Link } from "react-router-dom";
import Style from "../Style/Style";


const Carousel = ({
    rule,
}) => {

    const [myStyle1, setMyStyle1] = useState({
        gcs: Style.grid2Cs,
        gcsa: Style.grid2CsA,
        // gcsb: {display: "none"},
        // gcsc: {display: "none"},
        gcsb: Style.grid2CsB,
        gcsc: Style.grid2CsC,
        gcs1: Style.grid2CsChild1,
        gcs2: Style.grid2CsChild2,
        gcs3: Style.grid2CsChild3,
        gcs4: Style.grid2CsChild4,
        gcs5: Style.grid2CsChild5,
        gcs6: Style.grid2CsChild6,
        gcs7: Style.grid2CsChild7,
        gcs8: Style.grid2CsChild8,
        bt1: Style.gridLinkChild1,
        bt2: Style.gridLinkChild2,
        flex: Style.f,
        flex1: Style.f1,
        flex2: Style.f2,
        flex3: Style.f3,
    })


    const hoverBt1 = () => {

        setMyStyle1({
            ...myStyle1,
            bt1: {
                border: "3px solid orangered", padding: "3px 15px 3px 15px",
                gridArea: "--var(ch1)", textDecoration: "none", color: "white",
                backgroundColor: "orangerd",
            },
        })
    }

    const outBt1 = () => {
        setMyStyle1({
            ...myStyle1,
            bt1: {
                border: "3px solid white", padding: "3px 15px 3px 15px",
                gridArea: "--var(ch2)", textDecoration: "none", color: "white",
            },
        })
    }

    const hoverBt2 = () => {

        setMyStyle1({
            ...myStyle1,
            bt2: {
                border: "3px solid orangered", padding: "3px 15px 3px 15px",
                gridArea: "--var(ch1)", textDecoration: "none", color: "white",
                backgroundColor: "orangerd",
            },
        })
    }


    const outBt2 = () => {
        setMyStyle1({
            ...myStyle1,
            bt2: {
                border: "3px solid white", padding: "3px 15px 3px 15px",
                gridArea: "--var(ch2)", textDecoration: "none", color: "white",
            },
        })
    }

    const[x,setX] = useState(0)

    const prevClick = (e) => {
        e.preventDefault()
        setTimeout(() => {
            setX(x - 1)
        }, 1500);
        if(x == 0){
            setTimeout(() => {
                setX(0)
            }, 1500);
        }
    }

    const nextClick = (e) => {
        e.preventDefault()
        setTimeout(() => {
            setX(x + 1)
        }, 1500);
        if(x == 2){
            setTimeout(() => {
                setX(2)
            }, 1500);
        }
    }


    return (
        <>
            <section style={myStyle1.flex}>
                <div style={myStyle1.flex1}>
                    <Link
                        to={""}
                        style={myStyle1.bt1}
                        onClick={prevClick}
                        onMouseOver={hoverBt1}
                        onMouseOut={outBt1}
                        className="hover-grid-link-child1"
                    >
                        Prev
                    </Link>
                </div>
                <div style={myStyle1.flex2}>

                    {
                        (x == 0) &&

                        <div style={myStyle1.gcsa}>
                            
                            <Link
                                to={"/"}
                                style={myStyle1.gcs1}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs2}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs3}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs4}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs5}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs6}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs7}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs8}
                                className="hover-grid"
                            >
                            </Link>

                        </div>
                    }
                    
                    {
                     
                    (x == 1)&&
 
                        <div style={myStyle1.gcsb}>
                            
                            <Link
                                to={"/"}
                                style={myStyle1.gcs1}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs2}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs3}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs4}
                                className="hover-grid"
                            >
                            </Link>
                            {/* <Link
                                to={"/"}
                                style={myStyle1.gcs5}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs6}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs7}
                                className="hover-grid"
                            >
                            </Link> */}
                            <Link
                                to={"/"}
                                style={myStyle1.gcs8}
                                className="hover-grid"
                            >
                            </Link>

                         </div>
                        }
                        
                        {
                        (x == 2) &&

                        <div style={myStyle1.gcsc}>

                            {/* <Link
                                to={"/"}
                                style={myStyle1.gcs1}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs2}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs3}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs4}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs5}
                                className="hover-grid"
                            >
                            </Link> */}
                            <Link
                                to={"/"}
                                style={myStyle1.gcs6}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs7}
                                className="hover-grid"
                            >
                            </Link>
                            <Link
                                to={"/"}
                                style={myStyle1.gcs8}
                                className="hover-grid"
                            >
                            </Link>

                        </div>
                        
                    }
                </div>
                <div style={myStyle1.flex3}>
                    <Link
                        to={""}
                        style={myStyle1.bt2}
                        onClick={nextClick}
                        onMouseOver={hoverBt2}
                        onMouseOut={outBt2}
                        className="hover-grid-link-child2"
                    >
                        Next
                    </Link>
                </div>
            </section>
        </>
    );
}

export default Carousel;




// import ReactCSSTransitionGroup from 'react-transition-group'; // ES6
// var ReactCSSTransitionGroup = require('react-transition-group'); // ES5 with npm

// class TodoList extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {items: ['hello', 'world', 'click', 'me']};
//     this.handleAdd = this.handleAdd.bind(this);
//   }

//   handleAdd() {
//     const newItems = this.state.items.concat([
//       prompt('Enter some text')
//     ]);
//     this.setState({items: newItems});
//   }

//   handleRemove(i) {
//     let newItems = this.state.items.slice();
//     newItems.splice(i, 1);
//     this.setState({items: newItems});
//   }

//   render() {
//     const items = this.state.items.map((item, i) => (
//       <div key={item} onClick={() => this.handleRemove(i)}>
//         {item}
//       </div>
//     ));

//     return (
//       <div>
//         <button onClick={this.handleAdd}>Add Item</button>
//         <ReactCSSTransitionGroup
//           transitionName="example"
//           transitionEnterTimeout={500}
//           transitionLeaveTimeout={300}>
//           {items}
//         </ReactCSSTransitionGroup>
//       </div>
//     );
//   }
// }