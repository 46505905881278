import React from "react";
import Style from "../Style/Style";


const Image = ({
    rule, source, link, className
}) => {
    return (
        <>
            {
                (rule == "image1") &&

                <img
                    style={Style.elementHeader}
                    src={source}
                />
            }

            {
                (rule == "image2") &&

                <img
                    style={Style.elementHeader2}
                    src={source}
                />
            }

            {
                (rule == "image3") &&

                <img
                    width={"50%"}
                    height={"100%"}
                    src={source}
                />
            }

            {
                (rule == "image4") &&

                <img
                    style={Style.elementHeader4}
                    src={source}
                />
            }

            {
                (rule == "img-s") &&

                <img
                    src={link}
                    className={className}
                />
            }

        </>
    );
}

export default Image;