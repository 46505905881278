export default {
    body:{width: "100%",backgroundColor: "white",color: "white",
    fontFamily: "--var('Roboto', sans-serif)"},
    header: {display: "grid",backgroundColor: "transparent",padding: "15px 15px 15px 15px",
    gridTemplateRows: "39px",gridTemplateColumns: "1fr",
    gridTemplateAreas: "ch1 ch1",position: "fixed",top:"0px",right:"0px",
    left:"0px",},
    headerChild1: {display: "flex",alignItems: "center",justifyContent: "left",gridArea: "--var(ch1)",fontSize: "15px"},
    headerChild2: {gridArea: "--var(ch2)"},
    headerChild3: {gridArea: "--var(ch3)"},
    linkStyle1:{color: "white",textDecoration: "none"},
    listStyle1: {display: "inline-flex",listStyle: "none"},
    grid1: {display: "grid",gridTemplateColumns:"1fr 1fr",
    gridTemplateRows: "600px 600px",gridTemplateAreas: "--var('ch1 ch2' 'ch3 ch4')",
    gap: "0",marginTop: "75px"},
    grid1Child1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/PRODUCTION2.png)",
    backgroundPosition: "center",},
    grid1Child2: {gridArea: "--var(ch2)",backgroundImage: "url(../../images/MUSIC.png)",
    backgroundPosition: "center",},
    grid1Child3: {gridArea: "--var(ch3)",backgroundImage: "url(../../images/COMMUNICATION.png)",
    backgroundPosition: "center",},
    grid1Child4: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/HGM.png)",
    backgroundPosition: "center",},
    grid2: {display: "grid",gridTemplateColumns:"1fr 1fr",
    gridTemplateRows: "600px 600px",gridTemplateAreas: "--var('ch1 ch2' 'ch3 ch4')",
    gap: "0",},
    grid2Child1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/CONTENU_DU_SITE28.png)",
    backgroundPosition: "center"},
    grid2Child2: {gridArea: "--var(ch2)",backgroundImage: "url(../../images/CONTENU_DU_SITE31.png)",
    backgroundPosition: "center"},
    grid2Child3: {gridArea: "--var(ch3)",backgroundImage: "url(../../images/CONTENU_DU_SITE35.png)",
    backgroundPosition: "center"},
    grid2Child4: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/CONTENU_DU_SITE38.png)",
    backgroundPosition: "center"},
    grid2Card: {display: "grid",gridTemplateColumns:"1fr 1fr",
    gridTemplateRows: "600px",gridTemplateAreas: "--var('ch3 ch4')",
    gap: "0",},
    grid2CardChild1: {gridArea: "--var(ch3)",backgroundImage: "url(../../images/HCMJ.png)",
    backgroundPosition: "center"},
    grid2CardChild2: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/rsmusic2.png)",
    backgroundPosition: "center"},
    grid4Card: {display: "grid",gridTemplateColumns:"1fr 1fr",
    gridTemplateRows: "600px",gridTemplateAreas: "--var('ch3 ch4')",
    gap: "0",},
    grid4CardChild1: {gridArea: "--var(ch3)",backgroundImage: "url(../../images/rsmusic2.png)",
    backgroundPosition: "center"},
    grid4CardChild2: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/rscom-1.png)",
    backgroundPosition: "center"},
    grid6Card: {display: "grid",gridTemplateColumns:"1fr 1fr",
    gridTemplateRows: "600px",gridTemplateAreas: "--var('ch3 ch4')",
    gap: "0",},
    grid6CardChild1: {gridArea: "--var(ch3)",backgroundImage: "url(../../images/rscom-1.png)",
    backgroundPosition: "center"},
    grid6CardChild2: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/PRODUCT2.png)",
    backgroundPosition: "center"},
    f:{display: "flex",alignItems: "center",justifyContent:"center"},
    f1:{flexGrow: "1",textAlign:"center"},
    f2:{flexGrow: "10",overflow: "hidden"},
    f3:{flexGrow: "1",textAlign:"center"},
    grid2Cs: {display: "grid",padding: "0px 0px 0px 0px",
    gridTemplateColumns:"1fr 1fr 1fr",marginBottom:"30px",
    gridTemplateRows: "450px 450px 450px",
    gridTemplateAreas: "--var('ch1 ch2 ch3' 'ch4 ch5 ch6' 'ch7 ch8')",
    gap: "2rem",},
    grid2CsA: {display: "grid",color: "white",padding: "0px 0px 0px 0px",
    gridTemplateColumns:"1fr 1fr 1fr",marginBottom:"30px",
    gridTemplateRows: "450px 450px 450px",
    gridTemplateAreas: "--var('ch1 ch2 ch3' 'ch4 ch5 ch6' 'ch7 ch8')",
    gap: "2rem",},
    grid2CsB: {display: "grid",color: "white",padding: "0px 0px 0px 0px",
    gridTemplateColumns:"1fr 1fr 1fr",marginBottom:"30px",
    gridTemplateRows: "450px 450px 450px",
    gridTemplateAreas: "--var('ch1 ch2 ch3' 'ch4 ch5 ch6' 'ch7 ch8')",
    gap: "2rem",},
    grid2CsC: {display: "grid",color: "white",padding: "0px 0px 0px 0px",
    gridTemplateColumns:"1fr 1fr 1fr",marginBottom:"30px",
    gridTemplateRows: "450px 450px 450px",
    gridTemplateAreas: "--var('ch1 ch2 ch3' 'ch4 ch5 ch6' 'ch7 ch8')",
    gap: "2rem",},
    grid2CsChild1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/ga-1.png)",
    backgroundPosition: "center"},
    grid2CsChild2: {gridArea: "--var(ch2)",backgroundImage: "url(../../images/ga-2.png)",
    backgroundPosition: "center"},
    grid2CsChild3: {gridArea: "--var(ch3)",backgroundImage: "url(../../images/ga-3.png)",
    backgroundPosition: "center"},
    grid2CsChild4: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/ga-4.png)",
    backgroundPosition: "center"},
    grid2CsChild5: {gridArea: "--var(ch5)",backgroundImage: "url(../../images/ga-5.png)",
    backgroundPosition: "center"},
    grid2CsChild6: {gridArea: "--var(ch6)",backgroundImage: "url(../../images/ga-6.png)",
    backgroundPosition: "center"},
    grid2CsChild7: {gridArea: "--var(ch7)",backgroundImage: "url(../../images/ga-7.png)",
    backgroundPosition: "center"},
    grid2CsChild8: {gridArea: "--var(ch8)",backgroundImage: "url(../../images/ga-8.png)",
    backgroundPosition: "center"},
    grid2A: {display: "grid",gridTemplateColumns:"1fr 1fr",padding: "50px 50px 50px 50px",
    gridTemplateRows: "800px 800px",gridTemplateAreas: "--var('ch1 ch2' 'ch3 ch4')",
    gap: "2.0rem",},
    grid2AChild1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/CONTENU_DU_SITE2.png)",
    backgroundPosition: "center"},
    grid2AChild2: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch2)",},
    grid2AChild3: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid2AChild4: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/CONTENU_DU_SITE1.png)",
    backgroundPosition: "center"},
    grid3D: {display: "grid",gridTemplateColumns:"1fr 1fr",padding: "50px 50px 50px 50px",
    gridTemplateRows: "800px 800px",gridTemplateAreas: "--var('ch1 ch2' 'ch3 ch4')",
    gap: "2.0rem",},
    grid3DChild1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/CONTENU_DU_SITE22.png)",
    backgroundPosition: "center"},
    grid3DChild2: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch2)",},
    grid3DChild3: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid3DChild4: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/CONTENU_DU_SITE21.png)",
    backgroundPosition: "center"},
    grid1D: {display: "grid",gridTemplateColumns:"1fr 1fr",padding: "50px 50px 50px 50px",
    gridTemplateRows: "700px 700px 700px",
    gridTemplateAreas: "--var('ch1 ch2' 'ch3 ch4' 'ch5 ch6')",
    gap: "2.0rem",},
    grid1DChild1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/rs-prod2.png)",
    backgroundPosition: "center"},
    grid1DChild2: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch2)",},
    grid1DChild3: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid1DChild4: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/rs-prod3.png)",
    backgroundPosition: "center"},
    grid1DChild5: {gridArea: "--var(ch5)",backgroundImage: "url(../../images/rs-prod4.png)",
    backgroundPosition: "center"},
    grid1DChild6: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch6)",},
    // grid1DChild7: {display: "flex",alignItems:"center",justifyContent:"center",
    // backgroundColor: "transparent",gridArea: "--var(ch7)",},
    // grid1DChild8: {gridArea: "--var(ch8)",backgroundImage: "url(../../images/rs-prod5.png)",
    // backgroundPosition: "center"},
    grid2B: {display: "grid",gridTemplateColumns:"1fr 1fr",padding: "50px 50px 50px 50px",
    gridTemplateRows: "800px 800px 800px",gridTemplateAreas: "--var('ch5 ch6' 'ch1 ch2' 'ch3 ch4')",
    gap: "2.0rem",},
    grid2BChild1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/CONTENU_DU_SITE16.png)",
    backgroundPosition: "center"},
    grid2BChild2: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch2)",},
    grid2BChild3: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid2BChild4: {gridArea: "--var(ch4)",backgroundImage: "url(../../images/CONTENU_DU_SITE14.png)",
    backgroundPosition: "center"},
    grid2BChild5: {display: "flex",alignItems:"center",justifyContent:"center",
    backgroundColor: "transparent",gridArea: "--var(ch5)",},
    grid2BChild6: {gridArea: "--var(ch6)",backgroundImage: "url(../../images/CONTENU_DU_SITE13.png)",
    backgroundPosition: "center"},
    grid9: {display: "grid",gridTemplateColumns:"1fr 1fr",padding: "50px 50px 50px 50px",
    gridTemplateRows: "410px 410px",gridTemplateAreas: "--var('ch1 ch2' 'ch3 ch4')",
    gap: "0",},
    grid9Child1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/rs-prod5.png)",
    backgroundPosition: "center"},
    grid9Child2: {gridArea: "--var(ch2)",backgroundImage: "url(../../images/CONTENUESITE.png)",
    backgroundPosition: "center"},
    grid9Child3: {display: "flex",marginTop: "50px",justifyItems:"center",
    backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid9Child4: {display: "flex",marginTop: "50px",justifyItems:"center",
    backgroundColor: "transparent",gridArea: "--var(ch4)",},
    grid8A: {display: "grid",gridTemplateColumns:"1fr 1fr",padding: "50px 50px 50px 50px",
    gridTemplateRows: "410px 410px",gridTemplateAreas: "--var('ch1 ch2' 'ch3 ch4')",
    gap: "0",},
    grid8AChild1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/new_1.jpg)",
    backgroundPosition: "center"},
    grid8AChild2: {gridArea: "--var(ch2)",backgroundImage: "url(../../images/new_2.jpg)",
    backgroundPosition: "center"},
    grid8AChild3: {display: "flex",marginTop: "50px",justifyItems:"center",
    backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid8AChild4: {display: "flex",marginTop: "50px",justifyItems:"center",
    backgroundColor: "transparent",gridArea: "--var(ch4)",},
    grid10: {display: "grid",gridTemplateColumns:"1fr 4fr 1fr",
    // margin: "50px 50px 50px 50px",
    gridTemplateRows: "410px",gridTemplateAreas: "--var('ch1 ch2 ch3')",
    gap: "0",},
    grid10Child1: {display: "flex",marginTop: "50px",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch1)",},
    grid10Child2: {display: "flex",marginTop: "50px",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch2)",},
    grid10Child3: {display: "flex",marginTop: "50px",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid10B: {display: "grid",gridTemplateColumns:"1fr",
    padding: "0px 0px 0px 0px",gridTemplateAreas: "--var('ch2')",
    marginBottom: "150px",
    },
    grid10BChild1: {display: "flex",marginTop: "50px",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch1)",},
    grid10BChild2: {display: "flex",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch2)",},
    grid10BChild3: {display: "flex",marginTop: "50px",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid11: {display: "grid",gridTemplateColumns:"1fr 4fr 1fr",
    padding: "50px 50px 50px 50px",
    gridTemplateRows: "410px",gridTemplateAreas: "--var('ch1 ch2 ch3')",
    gap: "0",},
    grid11Child1: {display: "flex",marginTop: "50px",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch1)",},
    grid11Child2: {display: "flex",marginTop: "50px",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch2)",},
    grid11Child3: {display: "flex",marginTop: "50px",alignItems: "center",
    justifycontent:"center", backgroundColor: "transparent",gridArea: "--var(ch3)",},
    grid3: {display: "grid",alignItems:"center",justifyItems:"center",gridTemplateColumns:"1fr",
    gridTemplateRows: "100px",gridTemplateAreas: "--var('ch1 ch1')",
    backgroundColor: "white"},
    grid3Child1: {gridArea: "--var(ch1)",color:"black",},
    pCenter: {fontSize: "75px",wordSpacing: "15px",fontWeight: "900",},
    gridI: {display: "grid",alignItems:"center",justifyItems:"center",
    gridTemplateColumns:"1fr 4fr 1fr",gridTemplateRows: "200px",
    gridTemplateAreas: "--var('ch1 ch2 ch3')",
    backgroundColor: "transparent"},
    gridIChild1: {gridArea: "--var(ch1)",color:"white",},
    gridIChild2: {gridArea: "--var(ch2)",color:"white",textAlign:"center",
    lineHeight: "30px"},
    gridIChild3: {gridArea: "--var(ch3)",color:"white",},
    grid7: {display: "grid",alignItems:"center",justifyItems:"center",
    gridTemplateColumns:"1fr 4fr 1fr",gridTemplateRows: "200px",
    gridTemplateAreas: "--var('ch1 ch2 ch3')",
    backgroundColor: "transparent"},
    grid7Child1: {gridArea: "--var(ch1)",color:"white",},
    grid7Child2: {gridArea: "--var(ch2)",color:"white",textAlign:"center",
    lineHeight: "30px"},
    grid7Child3: {gridArea: "--var(ch3)",color:"white",},
    pCenter1: {fontSize: "30px",letterSpacing:"10px",
    fontWeight: "900",},
    pCenter2: {fontWeight: "900",padding:"15px",letterSpacing:"0.1rem",},
    flex1: {display: "flex",flexDirection: "column",alignItems: "center",
    justifyContent: "center",position: "relative",top: "72%",color:"white"
    ,opacity: "0.0"},
    flex1Child1: {flexGrow: "1",marginBottom: "40px",},
    flex1Child2: {flexGrow: "1",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white",textAlign:"center",},
    flex2: {display: "flex",flexDirection: "column",alignItems: "center",
    justifyContent: "center",position: "relative",top: "72%",color:"white",
    opacity: "0.0"},
    flex2Child1: {flexGrow: "1",marginBottom: "40px"},
    flex2Child2: {flexGrow: "1",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white",textAlign:"center"},
    flex3: {display: "flex",flexDirection: "column",alignItems: "center",
    justifyContent: "center",position: "relative",top: "72%",color:"white",
    opacity: "0.0"},
    flex3Child1: {flexGrow: "1",marginBottom: "40px"},
    flex3Child2: {flexGrow: "1",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white",textAlign:"center"},
    flex4: {display: "flex",flexDirection: "column",alignItems: "center",
    justifyContent: "center",position: "relative",top: "72%",color:"white",
    opacity: "0.0"},
    flex4Child1: {flexGrow: "1",marginBottom: "40px"},
    flex4Child2: {flexGrow: "1",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white",textAlign:"center"},
    flex5: {display: "flex",flexDirection: "column",alignItems: "center",
    justifyContent: "center",position: "relative",top: "72%",color:"white",
    opacity: "0.0"},
    flex5Child1: {flexGrow: "1",marginBottom: "40px"},
    flex5Child2: {flexGrow: "1",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white",textAlign:"center"},
    grid4: {display: "grid",alignItems:"center",justifyItems:"center",
    gridTemplateColumns:"1fr 1fr",gridTemplateAreas: "--var('ch1 ch2')",
    gap: "200px"},
    grid4Child1:{gridArea: "--var(ch1)",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white",},
    grid4Child2:{gridArea: "--var(ch2)",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white"},
    grid5: {display: "grid",alignItems:"center",justifyItems:"center",
    gridTemplateColumns:"1fr 1fr",gridTemplateAreas: "--var('ch1 ch2')",
    gap: "200px"},
    grid5Child1:{gridArea: "--var(ch1)",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white"},
    grid5Child2:{gridArea: "--var(ch2)",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white"},
    grid6: {display: "grid",alignItems:"center",justifyItems:"center",
    gridTemplateColumns:"1fr 1fr",gridTemplateAreas: "--var('ch1 ch2')",
    gap: "200px"},
    grid6Child1:{gridArea: "--var(ch1)",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white"},
    grid6Child2:{gridArea: "--var(ch2)",fontSize:"25px",fontWeight: "700",
    textDecoration: "none",color: "white"},
    elementHeader: {marginTop: "75px",width:"100%"},
    elementHeader4: {marginTop: "75px",width:"100%",height: "548px"},
    elementHeader1: {objectFit: "cover",width: "100%",
    height: "40vw",marginTop: "75px",zIndex: "-1"},
    elementHeader2: {width:"100%",},
    elementHeader3: {objectFit: "cover",width: "100%",
    height: "40vw",zIndex: "-1"},
    flex7: {display: "flex",alignItems:"center",justifyItems:"center",
    backgroundColor: "transparent"},
    // flex7Child1: {flexGrow: "1",color:"white",},
    flex7Child2: {flexGrow: "4",color:"white",textAlign:"center",
    lineHeight: "40px"},
    // flex7Child3: {flexGrow: "1",color:"white",},
    pCenter1: {fontSize: "30px",letterSpacing:"0.4rem",
    fontWeight: "900",},
    pCenter2: {fontWeight: "900",padding:"15px",letterSpacing:"0.1rem",},
    flex9: {display: "flex",alignItems:"center",justifyItems:"center",
    backgroundColor: "transparent"},
    // flex9Child1: {flexGrow: "1",color:"white",},
    flex9Child2: {flexGrow: "1",color:"white",textAlign:"center",
    lineHeight: "40px"},
    // flex9Child3: {flexGrow: "1",color:"white",},
    pCenter3: {fontSize: "30px",letterSpacing:"0.4rem",
    fontWeight: "900",},
    pCenter4: {fontWeight: "900",padding:"15px",letterSpacing:"0.1rem",},
    gridLink: {display: "grid",alignItems:"center",justifyItems:"center",
    gridTemplateColumns:"1fr",gridTemplateAreas: "--var('ch1 ch2')",},
    gridLink1: {display: "grid",alignItems:"center",justifyItems:"center",
    gridTemplateColumns:"1fr 1fr",gridTemplateAreas: "--var('ch1 ch2')",},
    gridLinkChild1: {border: "3px solid white",padding:"3px 15px 3px 15px",
    gridArea: "--var(ch1)",textDecoration:"none",color:"white"},
    gridLinkChild2: {border: "3px solid white",padding:"3px 15px 3px 15px",
    gridArea: "--var(ch2)",textDecoration:"none",color:"white"},
    pCenter01: {fontSize: "30px",letterSpacing:"0.4rem",padding:"50px",
    fontWeight: "900",},
    pCenter016: {fontSize: "30px",letterSpacing:"0.4rem",padding:"50px",
    fontWeight: "900",textAlign:"center"},
    pCenter017: {fontSize: "15px",fontWeight: "900",},
    // pCenter1: {fontSize: "30px",letterSpacing:"0.4rem",
    // fontWeight: "900",},
    blockTem: {display: "block",padding:  "100px",color: "white"},
    flexF1: {display:"flex",marginBottom:"20px"},
    gridG1:{display:"grid",
    gridTemplateColumns:"1fr 3fr",gridTemplateAreas:"ch1 ch2",gap:"1em"},
    gridG2:{display:"grid",
    gridTemplateColumns:"1fr 3fr",gridTemplateAreas:"ch1 ch2",gap:"1em"},
    gridG3:{display:"grid",
    gridTemplateColumns:"1fr 3fr",gridTemplateAreas:"ch1 ch2",gap:"1em"},
    blockB1:{display:"block",gridAreas:"--var(ch1)",textAlign:"right"},
    blockB2:{display:"flex",gridAreas:"--var(ch2)",flexDirection:"column"},
    blockB3:{display:"block",gridAreas:"--var(ch1)",textAlign:"right"},
    blockB4:{display:"flex",gridAreas:"--var(ch2)",flexDirection:"column"},
    blockB5:{display:"block",gridAreas:"--var(ch1)",textAlign:"right"},
    blockB6:{display:"flex",gridAreas:"--var(ch2)",flexDirection:"column"},
    flexF2: {display:"flex",},
    imgAudio1: {fontSize: "35px",cursor: "pointer"},
    imgAudio2: {display: "none",fontSize: "35px",cursor: "pointer",},
    inputAudio: {height: "4px",fontSize: "small",color: "#fff",
    backgroundColor: "#fff",cursor: "pointer",zIndex: "100"},
    progress: {height: "4px",},
    footer:{
        marginTop: "100px"
    }
}
