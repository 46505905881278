import { React, useRef, useState } from "react";
import Style from "../Style/Style";
import { useEffect } from "react";


const AudioFile = ({
    rule, MySrc
}) => {
    const [audioStyle, setAudioStyle] = useState({
        sp1: "play-style1",
        sp2: "pause-style1",
        inputAudio: Style.inputAudio,
        progress: Style.progress
    })
    const [manageAudio, setManageAudio] = useState({
        valueRange: null,
    })
    const [valueRange, setValueRange] = useState(null)
    const [audioElementA, setAudioElementA] = useState(null)
    const [duration, setDuration] = useState(0)
    const [timeNow, setTimeNow] = useState(0)
    const [max, setMax] = useState(100)
    const [min, setMin] = useState(0)
    const [valueA, setValueA] = useState(0)
    const [range, setRange] = useState()
    const [progress, setProgress] = useState()
    let zero = 0
    let audInt = false
    const myRef = useRef(null)
    const timeOutId = useState(null)

    

    if (rule == "web-api-audio1") {
        setTimeout(() => {
            setAudioElementA((document.querySelector("#audio1")))
            setRange(document.querySelector("#progress-bar"))
            setProgress(document.querySelector("#progress-bar"))
        });


        const changeRangeAudio = (e) => {
            let { value } = e.target
            if(value != audioElementA.currentTime){
                audioElementA.currentTime = value
            }
            
            setMax(audioElementA.duration)
            if(audioElementA.paused){
                audioPlay()
            }
        }
        const timeUpdate = () => {
            setInterval(() => {
                setValueA(audioElementA.currentTime)
            }, 100);
        }
        
        const audioPause = () => {
            if (!audioElementA.paused) {
                audioElementA.pause()
            }

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })

        }

        

        const audioPlay = (e) => {
            if (audioElementA.paused) {
                audioElementA.play()
            }

            setDuration(Math.round((audioElementA.duration / 60) * 100) / 100)

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style2",
                sp2: "pause-style2",
            })

            setInterval(() => {
                setTimeNow(Math.round((audioElementA.currentTime / 60) * 100) / 100)
            }, 0);

        }



        const audioEnded = () => {
            audioElementA.pause()
            audioElementA.currentTime = 0
            setValueA(0)
            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })
        }

        return (
            <>
                <div style={{ display: "flex" }}>
                    <img
                        onClick={audioPlay}
                        id="spl"
                        className={audioStyle.sp1}
                        src={"../../bootstrap-icons/icons/play-fill.svg"}
                    >
                    </img>
                    <img
                        onClick={audioPause}
                        id="spa"
                        className={audioStyle.sp2}
                        src={"../../bootstrap-icons/icons/pause-fill.svg"}
                    >
                    </img>
                    <input 
                        type="range"
                        className=""
                        id="progress-bar"
                        aria-labelledby="progress"
                        onChange={changeRangeAudio}
                        min={min}
                        aria-valuemax={duration}
                        aria-valuenow={timeNow}
                        aria-valuemin={0}
                        value={valueA}
                        max={max}
                        step={"0.1"}
                    ></input>
                    <audio
                        onEnded={audioEnded}
                        onTimeUpdate={timeUpdate}
                        src={MySrc} id="audio1"
                        aria-describedby="progress-bar" aria-busy="true"
                    />
                    &nbsp;&nbsp;
                    <span className="">
                        {timeNow} / {duration}
                    </span>
                </div>

            </>
        )
    }

    if (rule == "web-api-audio2") {
        setTimeout(() => {
            setAudioElementA((document.querySelector("#audio2")))
            setRange(document.querySelector("#progress-bar"))
            setProgress(document.querySelector("#progress-bar"))
        });


        const changeRangeAudio = (e) => {
            let { value } = e.target
            if(value != audioElementA.currentTime){
                audioElementA.currentTime = value
            }
            
            setMax(audioElementA.duration)
            if(audioElementA.paused){
                audioElementA.currentTime = value
                audioPlay()
            }
        }
        const timeUpdate = () => {
            setInterval(() => {
                setValueA(audioElementA.currentTime)
            }, 100);
        }
        
        const audioPause = () => {
            if (!audioElementA.paused) {
                audioElementA.pause()
            }

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })

        }

        

        const audioPlay = (e) => {
            if (audioElementA.paused) {
                audioElementA.play()
            }

            setDuration(Math.round((audioElementA.duration / 60) * 100) / 100)

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style2",
                sp2: "pause-style2",
            })

            setInterval(() => {
                setTimeNow(Math.round((audioElementA.currentTime / 60) * 100) / 100)
            }, 0);

        }



        const audioEnded = () => {
            audioElementA.pause()
            audioElementA.currentTime = 0
            setValueA(0)
            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })
        }


        return (
            <>
                <div style={{display:"flex",alignItems:"center"}}>
                    <img
                        onClick={audioPlay}
                        id="spl"
                        className={audioStyle.sp1}
                        src={"../../bootstrap-icons/icons/play-fill.svg"}
                    >
                    </img>
                    <img
                        onClick={audioPause}
                        id="spa"
                        className={audioStyle.sp2}
                        src={"../../bootstrap-icons/icons/pause-fill.svg"}
                    >
                    </img>
                    <input
                        type="range"
                        className=""
                        id="progress-bar"
                        aria-labelledby="progress"
                        onChange={changeRangeAudio}
                        min={min}
                        aria-valuemax={duration}
                        aria-valuenow={timeNow}
                        aria-valuemin={0}
                        value={valueA}
                        max={max}
                        step={"0.1"}
                    ></input>
                    <audio
                        onEnded={audioEnded}
                        onTimeUpdate={timeUpdate}
                        src={MySrc} id="audio2"
                        aria-describedby="progress-bar" aria-busy="true"
                    />
                    &nbsp;&nbsp;
                    <span className="">
                        {timeNow} / {duration}
                    </span>
                </div>
            </>
        )
    }

    if (rule == "web-api-audio3") {
        setTimeout(() => {
            setAudioElementA((document.querySelector("#audio3")))
            setRange(document.querySelector("#progress-bar"))
            setProgress(document.querySelector("#progress-bar"))
        });


        const changeRangeAudio = (e) => {
            let { value } = e.target
            if(value != audioElementA.currentTime){
                audioElementA.currentTime = value
            }
            
            setMax(audioElementA.duration)
            if(audioElementA.paused){
                audioPlay()
            }
        }
        const timeUpdate = () => {
            setInterval(() => {
                setValueA(audioElementA.currentTime)
            }, 100);
        }
        
        const audioPause = () => {
            if (!audioElementA.paused) {
                audioElementA.pause()
            }

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })

        }

        

        const audioPlay = (e) => {
            if (audioElementA.paused) {
                audioElementA.play()
            }

            setDuration(Math.round((audioElementA.duration / 60) * 100) / 100)

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style2",
                sp2: "pause-style2",
            })

            setInterval(() => {
                setTimeNow(Math.round((audioElementA.currentTime / 60) * 100) / 100)
            }, 0);

        }



        const audioEnded = () => {
            audioElementA.pause()
            audioElementA.currentTime = 0
            setValueA(0)
            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })
        }



        return (
            <>
                <div style={{display:"flex",alignItems:"center"}}>
                    <img
                        onClick={audioPlay}
                        id="spl"
                        className={audioStyle.sp1}
                        src={"../../bootstrap-icons/icons/play-fill.svg"}
                    >
                    </img>
                    <img
                        onClick={audioPause}
                        id="spa"
                        className={audioStyle.sp2}
                        src={"../../bootstrap-icons/icons/pause-fill.svg"}
                    >
                    </img>
                    <input
                        type="range"
                        className=""
                        id="progress-bar"
                        aria-labelledby="progress"
                        onChange={changeRangeAudio}
                        min={min}
                        aria-valuemax={duration}
                        aria-valuenow={timeNow}
                        aria-valuemin={0}
                        value={valueA}
                        max={max}
                        step={"0.1"}
                    ></input>
                    <audio
                        onEnded={audioEnded}
                        onTimeUpdate={timeUpdate}
                        src={MySrc} id="audio3"
                        aria-describedby="progress-bar" aria-busy="true"
                    />
                    &nbsp;&nbsp;
                    <span className="">
                        {timeNow} / {duration}
                    </span>
                </div>
            </>
        )
    }

    if (rule == "web-api-audio4") {
        setTimeout(() => {
            setAudioElementA((document.querySelector("#audio4")))
            setRange(document.querySelector("#progress-bar"))
            setProgress(document.querySelector("#progress-bar"))
        });


        const changeRangeAudio = (e) => {
            let { value } = e.target
            if(value != audioElementA.currentTime){
                audioElementA.currentTime = value
            }
            
            setMax(audioElementA.duration)
            if(audioElementA.paused){
                audioPlay()
            }
        }
        const timeUpdate = () => {
            setInterval(() => {
                setValueA(audioElementA.currentTime)
            }, 100);
        }
        
        const audioPause = () => {
            if (!audioElementA.paused) {
                audioElementA.pause()
            }

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })

        }

        

        const audioPlay = (e) => {
            if (audioElementA.paused) {
                audioElementA.play()
            }

            setDuration(Math.round((audioElementA.duration / 60) * 100) / 100)

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style2",
                sp2: "pause-style2",
            })

            setInterval(() => {
                setTimeNow(Math.round((audioElementA.currentTime / 60) * 100) / 100)
            }, 0);

        }



        const audioEnded = () => {
            audioElementA.pause()
            audioElementA.currentTime = 0
            setValueA(0)
            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })
        }




        return (
            <>
                <div style={{display:"flex",alignItems:"center"}}>
                    <img
                        onClick={audioPlay}
                        id="spl"
                        className={audioStyle.sp1}
                        src={"../../bootstrap-icons/icons/play-fill.svg"}
                    >
                    </img>
                    <img
                        onClick={audioPause}
                        id="spa"
                        className={audioStyle.sp2}
                        src={"../../bootstrap-icons/icons/pause-fill.svg"}
                    >
                    </img>
                    <input
                        type="range"
                        className=""
                        id="progress-bar"
                        aria-labelledby="progress"
                        onChange={changeRangeAudio}
                        min={min}
                        aria-valuemax={duration}
                        aria-valuenow={timeNow}
                        aria-valuemin={0}
                        value={valueA}
                        max={max}
                        step={"0.1"}
                    ></input>
                    <audio
                        onEnded={audioEnded}
                        onTimeUpdate={timeUpdate}
                        src={MySrc} id="audio4"
                        aria-describedby="progress-bar" aria-busy="true"
                    />
                    &nbsp;&nbsp;
                    <span className="">
                        {timeNow} / {duration}
                    </span>
                </div>
            </>
        )
    }

    if (rule == "web-api-audio5") {
        setTimeout(() => {
            setAudioElementA((document.querySelector("#audio5")))
            setRange(document.querySelector("#progress-bar"))
            setProgress(document.querySelector("#progress-bar"))
        });


        const changeRangeAudio = (e) => {
            let { value } = e.target
            if(value != audioElementA.currentTime){
                audioElementA.currentTime = value
            }
            
            setMax(audioElementA.duration)
            if(audioElementA.paused){
                audioPlay()
            }
        }
        const timeUpdate = () => {
            setInterval(() => {
                setValueA(audioElementA.currentTime)
            }, 100);
        }
        
        const audioPause = () => {
            if (!audioElementA.paused) {
                audioElementA.pause()
            }

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })

        }

        

        const audioPlay = (e) => {
            if (audioElementA.paused) {
                audioElementA.play()
            }

            setDuration(Math.round((audioElementA.duration / 60) * 100) / 100)

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style2",
                sp2: "pause-style2",
            })

            setInterval(() => {
                setTimeNow(Math.round((audioElementA.currentTime / 60) * 100) / 100)
            }, 0);

        }



        const audioEnded = () => {
            audioElementA.pause()
            audioElementA.currentTime = 0
            setValueA(0)
            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })
        }



        return (
            <>
                <div style={{display:"flex",alignItems:"center"}}>
                    <img
                        onClick={audioPlay}
                        id="spl"
                        className={audioStyle.sp1}
                        src={"../../bootstrap-icons/icons/play-fill.svg"}
                    >
                    </img>
                    <img
                        onClick={audioPause}
                        id="spa"
                        className={audioStyle.sp2}
                        src={"../../bootstrap-icons/icons/pause-fill.svg"}
                    >
                    </img>
                    <input
                        type="range"
                        className=""
                        id="progress-bar"
                        aria-labelledby="progress"
                        onChange={changeRangeAudio}
                        min={min}
                        aria-valuemax={duration}
                        aria-valuenow={timeNow}
                        aria-valuemin={0}
                        value={valueA}
                        max={max}
                        step={"0.1"}
                    ></input>
                    <audio
                        onEnded={audioEnded}
                        onTimeUpdate={timeUpdate}
                        src={MySrc} id="audio5"
                        aria-describedby="progress-bar" aria-busy="true"
                    />
                    &nbsp;&nbsp;
                    <span className="">
                        {timeNow} / {duration}
                    </span>
                </div>
            </>
        )
    }

    if (rule == "web-api-audio6") {
        setTimeout(() => {
            setAudioElementA((document.querySelector("#audio6")))
            setRange(document.querySelector("#progress-bar"))
            setProgress(document.querySelector("#progress-bar"))
        });


        const changeRangeAudio = (e) => {
            let { value } = e.target
            if(value != audioElementA.currentTime){
                audioElementA.currentTime = value
            }
            
            setMax(audioElementA.duration)
            if(audioElementA.paused){
                audioPlay()
            }
        }
        const timeUpdate = () => {
            setInterval(() => {
                setValueA(audioElementA.currentTime)
            }, 100);
        }
        
        const audioPause = () => {
            if (!audioElementA.paused) {
                audioElementA.pause()
            }

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })

        }

        

        const audioPlay = (e) => {
            if (audioElementA.paused) {
                audioElementA.play()
            }

            setDuration(Math.round((audioElementA.duration / 60) * 100) / 100)

            setAudioStyle({
                ...audioStyle,
                sp1: "play-style2",
                sp2: "pause-style2",
            })

            setInterval(() => {
                setTimeNow(Math.round((audioElementA.currentTime / 60) * 100) / 100)
            }, 0);

        }



        const audioEnded = () => {
            audioElementA.pause()
            audioElementA.currentTime = 0
            setValueA(0)
            setAudioStyle({
                ...audioStyle,
                sp1: "play-style1",
                sp2: "pause-style1",
            })
        }



        return (
            <>
                <div style={{display:"flex",alignItems:"center"}}>
                    <img
                        onClick={audioPlay}
                        id="spl"
                        className={audioStyle.sp1}
                        src={"../../bootstrap-icons/icons/play-fill.svg"}
                    >
                    </img>
                    <img
                        onClick={audioPause}
                        id="spa"
                        className={audioStyle.sp2}
                        src={"../../bootstrap-icons/icons/pause-fill.svg"}
                    >
                    </img>
                    <input
                        type="range"
                        className=""
                        id="progress-bar"
                        aria-labelledby="progress"
                        onChange={changeRangeAudio}
                        min={min}
                        aria-valuemax={duration}
                        aria-valuenow={timeNow}
                        aria-valuemin={0}
                        value={valueA}
                        max={max}
                        step={"0.1"}
                    ></input>
                    <audio
                        onEnded={audioEnded}
                        onTimeUpdate={timeUpdate}
                        src={MySrc} id="audio6"
                        aria-describedby="progress-bar" aria-busy="true"
                    />
                    &nbsp;&nbsp;
                    <span className="">
                        {timeNow} / {duration}
                    </span>
                </div>
            </>
        )
    }


    return (
        <>
            {
                (rule == "audio1") &&

                <audio
                    autoPlay
                    loop
                    muted
                    controls
                    style={Style.elementHeader2}
                >
                    <source src={MySrc}
                        type={"audio/mp4"}></source>
                </audio>

            }
        </>
    );
}

export default AudioFile;