import React, { useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import ProgressBar from "../Components/ProgressBar";
import { useEffect } from "react";
import ScrollButton from "../Components/ScrollButton";



const Template = ({ rule, children }) => {
    const [form,setForm] = useState({
        f1: 'f1',
        f2: 'f2',
    })

    useEffect(() => {
        redirectHead()
    },[])

    const redirectHead = () => {
        window.scrollTo(0,0)
    }

    const scrButton = (e) => {
        e.preventDefault()

        window.scrollTo(0,0)
    }

    const royalLoad = () => {
        window.addEventListener('scroll',() => {
            if(window.scrollY > 700){
                setForm({
                    ...form,
                    f2: 'f2-a'
                })
            }
        })
    }

    const scrOutA = () => {
        setForm({
            ...form,
            f2: 'f2-b'
        })
    }

    const scrOverA = () => {
        setForm({
            ...form,
            f2: 'f2-c'
        })
    }


    return (
        <>
            <ProgressBar
                form={form.f1}  
                identify={'progress-page'}
            >
            </ProgressBar>
            <section onLoad={royalLoad}>
                <Header
                    rule={rule}
                >
                </Header>

                <ScrollButton
                    rule={"scrollButton"}
                    style={form.f2}
                    scrButton={scrButton}
                    srcOver={scrOverA}
                    srcOut={scrOutA}
                >
                </ScrollButton>
                <article>{children}</article>

                <Footer></Footer>
            </section>
        </>
    );
}

export default Template;