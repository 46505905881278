import React from "react";
import { useState } from "react";
import LinkRoyal from "./LinkRoyal";
import Image from "./Image";


const ScrollButton = ({
    scrButton, style, srcOver, srcOut, rule,
}) => {

    const [form,setForm] = useState({
        scrBt: {
            display: "block",
            padding: "5px 5px 5px 5px",
            color: "black",
            position: "relative",
        },
    })

    if (rule == "scrollButton") {
        return (
            <section
                className={style}
                onMouseOver={srcOver}
                onMouseOut={srcOut}
            >
                <div
                    style={form.scrBt}
                >
                    <LinkRoyal
                        scrButton={scrButton}
                        rule={"scrButton"}
                        children={
                            <>
                                <Image
                                    rule={"img-s"}
                                    link={
                                        "../../bootstrap-icons/icons/chevron-up.svg"
                                    }
                                ></Image>
                            </>
                        }
                    >
                    </LinkRoyal>
                </div>
            </section>
        )
    }

}

export default ScrollButton;