import React, { useState } from "react";
import { Link } from "react-router-dom";
import Style from "../Style/Style";
import AudioFile from "./AudioFile";
import Carousel from "./Carousel";
import Image from "./Image";


const GridFlex = ({
    rule, content1, content2, content3, content4, content8,
    content9, content10, content11, content12, content13, content14,
    content16, content17, content18, content19
}) => {

    const [myStyle1, setMyStyle1] = useState({
        myStyle: Style.grid2,
        myStyle1: Style.grid2Child1,
        myStyle2: Style.grid2Child2,
        myStyle3: Style.grid2Child3,
        myStyle4: Style.grid2Child4,
        myStyle2Card: Style.grid2Card,
        myStyle2Card1: Style.grid2CardChild1,
        myStyle2Card2: Style.grid2CardChild2,
        myStyle4Card: Style.grid4Card,
        myStyle4Card1: Style.grid4CardChild1,
        myStyle4Card2: Style.grid4CardChild2,
        myStyle6Card: Style.grid6Card,
        myStyle6Card1: Style.grid6CardChild1,
        myStyle6Card2: Style.grid6CardChild2,
        gcs: Style.grid2Cs,
        gcs1: Style.grid2CsChild1,
        gcs2: Style.grid2CsChild2,
        gcs3: Style.grid2CsChild3,
        gcs4: Style.grid2CsChild4,
        gcs5: Style.grid2CsChild5,
        gcs6: Style.grid2CsChild6,
        gcs7: Style.grid2CsChild7,
        gcs8: Style.grid2CsChild8,
        myStyle2A: Style.grid2A,
        myStyle2A1: Style.grid2AChild1,
        myStyle2A2: Style.grid2AChild2,
        myStyle2A3: Style.grid2AChild3,
        myStyle2A4: Style.grid2AChild4,
        myStyle3D: Style.grid3D,
        myStyle3D1: Style.grid3DChild1,
        myStyle3D2: Style.grid3DChild2,
        myStyle3D3: Style.grid3DChild3,
        myStyle3D4: Style.grid3DChild4,
        myStyle1D: Style.grid1D,
        myStyle1D1: Style.grid1DChild1,
        myStyle1D2: Style.grid1DChild2,
        myStyle1D3: Style.grid1DChild3,
        myStyle1D4: Style.grid1DChild4,
        myStyle1D5: Style.grid1DChild5,
        myStyle1D6: Style.grid1DChild6,
        // myStyle1D7: Style.grid1DChild7,
        // myStyle1D8: Style.grid1DChild8,
        myStyle1: Style.grid2Child1,
        myStyle2: Style.grid2Child2,
        myStyle3: Style.grid2Child3,
        myStyle4: Style.grid2Child4,
        myStyle5: Style.flex1,
        myStyle6: Style.flex2,
        myStyle7: Style.flex4,
        myStyle8: Style.flex5,
        bt1: Style.gridLinkChild1,
        bt2: Style.gridLinkChild2
    })

    const [timeOutId, setTimeOutId] = useState(null)

    const hoverLink5 = () => {
        // setTimeOutId(() => setTimeout(() => {
        //     setMyStyle1({
        //         ...myStyle1,
        //         myStyle1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/CLIENTS1.png)",
        //         backgroundPosition: "center",opacity: "0.5"}
        //     })
        // }, 0))
    }

    const outLink5 = () => {
        // clearTimeout(timeOutId)

        // setMyStyle1({
        //     ...myStyle1,
        //     myStyle1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/CLIENTS1.png)",
        //     backgroundPosition: "center",},
        // })
    }
    const hoverLink6 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle2: {
                gridArea: "--var(ch2)", backgroundImage: "url(../../images/PROJETS2.png)",
                backgroundPosition: "center", opacity: "0.5"
            },
        })
    }

    const outLink6 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle2: {
                gridArea: "--var(ch2)", backgroundImage: "url(../../images/PROJETS2.png)",
                backgroundPosition: "center",
            },
        })
    }
    const hoverLink7 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle3: {
                gridArea: "--var(ch3)", backgroundImage: "url(../../images/HISTOIRES.png)",
                backgroundPosition: "center", opacity: "0.5"
            },
        })
    }

    const outLink7 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle3: {
                gridArea: "--var(ch3)", backgroundImage: "url(../../images/HISTOIRES.png)",
                backgroundPosition: "center",
            },
        })
    }

    const hoverLink9 = () => {

        setMyStyle1({
            ...myStyle1,
            myStyle5: {
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "center", position: "relative", top: "72%", color: "white",
                opacity: "1"
            },
        })
    }


    const outLink9 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle5: {
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "center", position: "relative", top: "72%", color: "white"
                , opacity: "0.0"
            },
        })
    }


    const hoverLink10 = () => {

        setMyStyle1({
            ...myStyle1,
            myStyle6: {
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "center", position: "relative", top: "72%", color: "white",
                opacity: "1"
            },
        })
    }


    const outLink10 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle6: {
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "center", position: "relative", top: "72%", color: "white",
                opacity: "0.0"
            },
        })
    }


    const hoverLink11 = () => {

        setMyStyle1({
            ...myStyle1,
            myStyle7: {
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "center", position: "relative", top: "72%", color: "white",
                opacity: "1"
            },
        })
    }


    const outLink11 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle7: {
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "center", position: "relative", top: "72%", color: "white",
                opacity: "0.0"
            },
        })

    }


    const hoverLink12 = () => {

        setMyStyle1({
            ...myStyle1,
            myStyle8: {
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "center", position: "relative", top: "72%", color: "white",
                opacity: "1"
            },
        })
    }


    const outLink12 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle8: {
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "center", position: "relative", top: "72%", color: "white",
                opacity: "0.0"
            },
        })
    }

    const hoverBt1 = () => {

        setMyStyle1({
            ...myStyle1,
            bt1: {
                border: "3px solid orangered", padding: "3px 15px 3px 15px",
                gridArea: "--var(ch1)", textDecoration: "none", color: "white",
                backgroundColor: "orangerd",
            },
        })
    }


    const outBt1 = () => {
        setMyStyle1({
            ...myStyle1,
            bt1: {
                border: "3px solid white", padding: "3px 15px 3px 15px",
                gridArea: "--var(ch2)", textDecoration: "none", color: "white",
            },
        })
    }

    const hoverBt2 = () => {

        setMyStyle1({
            ...myStyle1,
            bt2: {
                border: "3px solid orangered", padding: "3px 15px 3px 15px",
                gridArea: "--var(ch1)", textDecoration: "none", color: "white",
                backgroundColor: "orangerd",
            },
        })
    }


    const outBt2 = () => {
        setMyStyle1({
            ...myStyle1,
            bt2: {
                border: "3px solid white", padding: "3px 15px 3px 15px",
                gridArea: "--var(ch2)", textDecoration: "none", color: "white",
            },
        })
    }

    return (
        <>
            {
                (rule == "grid1") &&

                <section style={Style.grid1}>
                    <div
                        style={Style.grid1Child1}
                    >
                        <div
                            style={myStyle1.myStyle5}
                            onMouseOver={hoverLink9}
                            onMouseOut={outLink9}
                        >
                            <div
                                style={Style.flex1Child1}
                            >
                                <div
                                    style={Style.grid4}
                                >
                                    <Link
                                        to={"/"}
                                        style={Style.grid4Child1}
                                        className="hover-grid"
                                    >
                                        Service Arkos
                                    </Link>
                                    <Link
                                        to={"/"}
                                        style={Style.grid4Child2}
                                        className="hover-grid"
                                    >
                                        Service Skyforce
                                    </Link>
                                </div>
                            </div>
                            <Link
                                to={"/"}
                                style={Style.flex1Child2}
                                className="hover-grid"
                            >
                                Service Luxevision
                            </Link>
                        </div>
                    </div>
                    <div style={Style.grid1Child2}>
                        <div
                            style={myStyle1.myStyle6}
                            onMouseOver={hoverLink10}
                            onMouseOut={outLink10}
                        >
                            <div style={Style.flex2Child1}>
                                <div style={Style.grid5}>
                                    <Link
                                        to={"/"}
                                        style={Style.grid5Child1}
                                        className="hover-grid"
                                    >
                                        Faire carrière
                                    </Link>
                                    <Link
                                        to={"/"}
                                        style={Style.grid5Child2}
                                        className="hover-grid"
                                    >
                                        Streaming RSM
                                    </Link>
                                </div>
                            </div>
                            <div style={Style.flex2Child2}>
                                <div style={Style.grid6}>
                                    <Link
                                        to={"/"}
                                        style={Style.grid6Child1}
                                        className="hover-grid"
                                    >
                                        Réserver
                                    </Link>
                                    <Link
                                        to={"/"}
                                        style={Style.grid6Child2}
                                        className="hover-grid"
                                    >
                                        Média RSM
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={Style.grid1Child3}
                    >
                        <div
                            style={myStyle1.myStyle7}
                            onMouseOver={hoverLink11}
                            onMouseOut={outLink11}
                        >
                            <Link
                                to={"/"}
                                style={Style.flex4Child2}
                                className="hover-grid"
                            >
                                Services professionnels <br />de communication
                            </Link>
                        </div>
                    </div>
                    <div style={Style.grid1Child4}>
                        <div
                            style={myStyle1.myStyle8}
                            onMouseOver={hoverLink12}
                            onMouseOut={outLink12}
                        >
                            <Link
                                to={"/"}
                                style={Style.flex5Child2}
                                className="hover-grid"
                            >
                                Façonnez votre identité <br />haut de gamme
                            </Link>
                        </div>
                    </div>
                </section>
            }

            {
                (rule == "grid2A") &&

                <section style={myStyle1.myStyle2A}>

                    <div
                        // to={"/"}
                        style={myStyle1.myStyle2A1}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle2A2}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content8}</h2>
                            <p style={Style.pCenter2}>{content9}</p>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle2A3}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content10}</h2>
                            <p style={Style.pCenter2}>{content11}</p>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle2A4}
                    // className="hover-grid"
                    >
                    </div>
                </section>
            }

            {
                (rule == "grid3D") &&

                <section style={myStyle1.myStyle3D}>

                    <div
                        // to={"/"}
                        style={myStyle1.myStyle3D1}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle3D2}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content8}</h2>
                            <p style={Style.pCenter2}>{content9}</p>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle3D3}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content10}</h2>
                            <p style={Style.pCenter2}>{content11}</p>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle3D4}
                    // className="hover-grid"
                    >
                    </div>
                </section>
            }

            {
                (rule == "grid1D") &&

                <section style={myStyle1.myStyle1D}>

                    <div
                        // to={"/"}
                        style={myStyle1.myStyle1D1}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle1D2}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content8}</h2>
                            <p style={Style.pCenter2}>{content9}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    DÉCOUVREZ ARKOS
                                </Link>
                            </div>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle1D3}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content10}</h2>
                            <p style={Style.pCenter2}>{content11}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    DEVENEZ UNIQUE AVEC H.C.M
                                </Link>
                            </div>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle1D4}
                    // className="hover-grid"
                    >
                    </div>

                    <div
                        // to={"/"}
                        style={myStyle1.myStyle1D5}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={myStyle1.myStyle1D6}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content16}</h2>
                            <p style={Style.pCenter2}>{content17}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    DÉCOUVREZ RSM
                                </Link>
                            </div>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                </section>
            }

            {
                (rule == "grid2B") &&

                <section style={Style.grid2B}>

                    <div
                        // to={"/"}
                        style={Style.grid2BChild5}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content8}</h2>
                            <p style={Style.pCenter2}>{content9}</p>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid2BChild6}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid2BChild1}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid2BChild2}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content10}</h2>
                            <p style={Style.pCenter2}>{content11}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    DÉMARQUEZ-VOUS
                                </Link>
                                {/* <Link
                                    to={"/"}
                                    style={Style.gridLinkChild2}
                                    className="hover-grid"
                                >
                                </Link>     */}
                            </div>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid2BChild3}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex7Child1}></div> */}
                        <div style={Style.flex7Child2}>
                            <h2 style={Style.pCenter1}>{content13}</h2>
                            <p style={Style.pCenter2}>{content14}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    FRANCHISSEZ LE CAP
                                </Link>
                                {/* <Link
                                    to={"/"}
                                    style={Style.gridLinkChild2}
                                    className="hover-grid"
                                >
                                </Link>     */}
                            </div>
                        </div>
                        {/* <div style={Style.flex7Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid2BChild4}
                    // className="hover-grid"
                    >
                    </div>
                </section>
            }

            {
                (rule == "grid9") &&

                <section style={Style.grid9}>

                    <div
                        // to={"/"}
                        style={Style.grid9Child1}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid9Child2}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid9Child3}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex9Child1}></div> */}
                        <div style={Style.flex9Child2}>
                            <h2 style={Style.pCenter1}>{content8}</h2>
                            <p style={Style.pCenter2}>{content9}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    CONTACTEZ-NOUS
                                </Link>
                            </div>

                        </div>
                        {/* <div style={Style.flex9Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid9Child4}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex9Child1}></div> */}
                        <div style={Style.flex9Child2}>
                            <h2 style={Style.pCenter3}>{content10}</h2>
                            <p style={Style.pCenter4}>{content11}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt2}
                                    onMouseOver={hoverBt2}
                                    onMouseOut={outBt2}
                                    className="hover-grid-link-child2"
                                >
                                    AVIS CLIENTS
                                </Link>
                            </div>
                        </div>
                        {/* <div style={Style.grid9Child4}></div> */}
                    </div>
                </section>
            }

            {
                (rule == "grid8A") &&

                <section style={Style.grid8A}>

                    <div
                        // to={"/"}
                        style={Style.grid8AChild1}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid8AChild2}
                    // className="hover-grid"
                    >
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid8AChild3}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex9Child1}></div> */}
                        <div style={Style.flex9Child2}>
                            <h2 style={Style.pCenter1}>{content8}</h2>
                            <p style={Style.pCenter2}>{content9}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    DÉCOUVREZ RS COM
                                </Link>
                            </div>

                        </div>
                        {/* <div style={Style.flex9Child3}></div> */}
                    </div>
                    <div
                        // to={"/"}
                        style={Style.grid8AChild4}
                    // className="hover-grid"
                    >
                        {/* <div style={Style.flex9Child1}></div> */}
                        <div style={Style.flex9Child2}>
                            <h2 style={Style.pCenter3}>{content10}</h2>
                            <p style={Style.pCenter4}>{content11}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt2}
                                    onMouseOver={hoverBt2}
                                    onMouseOut={outBt2}
                                    className="hover-grid-link-child2"
                                >
                                    L'HISTOIRE RS
                                </Link>
                            </div>
                        </div>
                        {/* <div style={Style.grid9Child4}></div> */}
                    </div>
                </section>
            }

            {
                (rule == "grid11") &&

                <section style={Style.grid11}>
                    <div
                        style={Style.grid11Child1}
                    ></div>
                    <div
                        style={Style.grid11Child2}
                    >
                        <div style={Style.flex9Child2}>
                            <h2 style={Style.pCenter1}>{content12}</h2>
                            <p style={Style.pCenter2}>{content11}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    CONTACTEZ-NOUS
                                </Link>
                            </div>
                            <h2 style={Style.pCenter01}>{content10}</h2>
                        </div>
                        <div
                            style={Style.grid11Child3}
                        ></div>
                    </div>
                </section>
            }

            {
                (rule == "grid10") &&

                <section style={Style.grid10}>
                    <div
                        style={Style.grid10Child1}
                    ></div>
                    <div
                        style={Style.grid10Child2}
                    >
                        <div style={Style.flex9Child2}>
                            <p style={Style.pCenter2}>{content11}</p>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    CLIQUEZ POUR VOIR NOTRE SAVOIR-FAIRE
                                </Link>
                            </div>
                            <h2 style={Style.pCenter01}>{content10}</h2>
                        </div>
                        <div
                            style={Style.grid10Child3}
                        ></div>
                    </div>
                </section>
            }

            {
                (rule == "grid20") &&

                <section style={Style.grid10}>
                    <div
                        style={Style.grid10Child1}
                    ></div>
                    <div
                        style={Style.grid10Child2}
                    >
                        <div style={Style.flex9Child2}>
                            {/* <p style={Style.pCenter2}>{content11}</p> */}
                            <h2 style={Style.pCenter01}>{content10}</h2>
                            <div style={Style.gridLink}>
                                <Link
                                    to={"/"}
                                    style={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                    className="hover-grid-link-child1"
                                >
                                    CLIQUEZ ICI
                                </Link>
                            </div>
                        </div>
                        <div
                            style={Style.grid10Child3}
                        ></div>
                    </div>
                </section>
            }

            {
                (rule == "grid10B") &&

                <section style={Style.grid10B}>
                    {/* <div
                        style={Style.grid10BChild1}
                    ></div> */}
                    <div
                        style={Style.grid10BChild2}
                    >
                        <div style={Style.flex9Child2}>
                            <h2 style={Style.pCenter01}>{content10}</h2>
                        </div>
                    </div>
                    {/* <div
                        style={Style.grid10BChild3}
                    ></div> */}
                </section>
            }

            {
                (rule == "gridTem") &&

                <section style={Style.blockTem}>
                    <h2 style={Style.pCenter016}>
                        POUR VOS TEMOIGNAGES
                    </h2>
                    <div
                        style={Style.flexF1}
                    >
                        <div
                            style={Style.gridG1}
                        >
                            <div
                                style={Style.blockB1}
                            >
                                <Image
                                    rule={"image3"}
                                    source={"../../images/HGM.png"}
                                >
                                </Image>
                            </div>

                            <div
                                style={Style.blockB2}
                            >
                                <p>
                                    Directrice Marketing LUST SECRET
                                </p>
                                <AudioFile
                                    rule={"web-api-audio1"}
                                    MySrc={"../../m1.mp3"}
                                >
                                </AudioFile>
                            </div>
                        </div>
                        <div
                            style={Style.gridG2}
                        >
                            <div
                                style={Style.blockB3}
                            >
                                <Image
                                    rule={"image3"}
                                    source={"../../images/HGM.png"}
                                >
                                </Image>
                            </div>

                            <div
                                style={Style.blockB4}
                            >
                                <p>
                                    Fondatrice ALL CHEAPER
                                </p>
                                <AudioFile
                                    rule={"web-api-audio2"}
                                    MySrc={"../../m7.mp3"}
                                >
                                </AudioFile>
                            </div>
                        </div>
                        <div
                            style={Style.gridG3}
                        >
                            <div
                                style={Style.blockB5}
                            >
                                <Image
                                    rule={"image3"}
                                    source={"../../images/HGM.png"}
                                >
                                </Image>
                            </div>

                            <div
                                style={Style.blockB6}
                            >
                                <p>
                                    Responsable Publicité KING GOOD
                                </p>
                                <AudioFile
                                    rule={"web-api-audio3"}
                                    MySrc={"../../m3.mp3"}
                                >
                                </AudioFile>
                            </div>
                        </div>
                    </div>

                    <div
                        style={Style.flexF2}
                    >
                        <div
                            style={Style.gridG1}
                        >
                            <div
                                style={Style.blockB1}
                            >
                                <Image
                                    rule={"image3"}
                                    source={"../../images/HGM.png"}
                                >
                                </Image>
                            </div>

                            <div
                                style={Style.blockB2}
                            >
                                <p>
                                    Promoteur BTK FITNESS
                                </p>
                                <AudioFile
                                    rule={"web-api-audio4"}
                                    MySrc={"../../m4.mp3"}
                                >
                                </AudioFile>
                            </div>
                        </div>
                        <div
                            style={Style.gridG2}
                        >
                            <div
                                style={Style.blockB3}
                            >
                                <Image
                                    rule={"image3"}
                                    source={"../../images/HGM.png"}
                                >
                                </Image>
                            </div>

                            <div
                                style={Style.blockB4}
                            >
                                <p>
                                    Promoteur AJR BESPOKE
                                </p>
                                <AudioFile
                                    rule={"web-api-audio5"}
                                    MySrc={"../../m5.mp3"}
                                >
                                </AudioFile>
                            </div>
                        </div>
                        <div
                            style={Style.gridG3}
                        >
                            <div
                                style={Style.blockB5}
                            >
                                <Image
                                    rule={"image3"}
                                    source={"../../images/HGM.png"}
                                >
                                </Image>
                            </div>

                            <div
                                style={Style.blockB6}
                            >
                                <p>
                                    Fondatrice BLACK PEARL
                                </p>
                                <AudioFile
                                    rule={"web-api-audio6"}
                                    MySrc={"../../m6.mp3"}
                                >
                                </AudioFile>
                            </div>
                        </div>
                    </div>



                </section>
            }

            {
                (rule == "grid2") &&

                <section style={myStyle1.myStyle}>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle1}
                        className="hover-grid"
                    >
                    </Link>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle2}
                        className="hover-grid"
                    >
                    </Link>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle3}
                        className="hover-grid"
                    >
                    </Link>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle4}
                        className="hover-grid"
                    >
                    </Link>
                </section>
            }

            {
                (rule == "grid2V1") &&

                <section style={myStyle1.myStyle2Card}>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle2Card1}
                        className="hover-grid"
                    >
                    </Link>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle2Card2}
                        className="hover-grid"
                    >
                    </Link>
                </section>
            }

            {
                (rule == "grid2V4") &&

                <section style={myStyle1.myStyle4Card}>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle4Card1}
                        className="hover-grid"
                    >
                    </Link>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle4Card2}
                        className="hover-grid"
                    >
                    </Link>
                </section>
            }

            {
                (rule == "grid2V6") &&

                <section style={myStyle1.myStyle6Card}>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle6Card1}
                        className="hover-grid"
                    >
                    </Link>
                    <Link
                        to={"/"}
                        style={myStyle1.myStyle6Card2}
                        className="hover-grid"
                    >
                    </Link>
                </section>
            }

            {
                (rule == "grid2Cs") &&

                <>
                    <Carousel
                    >
                    </Carousel>
                    {/* <div style={Style.gridLink}>
                        <Link
                            to={"/"}
                            style={myStyle1.bt1}
                            onMouseOver={hoverBt1}
                            onMouseOut={outBt1}
                            className="hover-grid-link-child1"
                        >
                            Prev
                        </Link>
                        &nbsp;&nbsp;
                        <Link
                            to={"/"}
                            style={myStyle1.bt2}
                            onMouseOver={hoverBt2}
                            onMouseOut={outBt2}
                            className="hover-grid-link-child2"
                        >
                            Next
                        </Link>
                    </div> */}
                </>


            }

            {
                (rule == "grid3") &&

                <section style={Style.grid3}>
                    <div style={Style.grid3Child1}>
                        <p style={Style.pCenter}>{content8}</p>
                    </div>
                </section>
            }

            {
                (rule == "grid4") &&

                <section style={Style.grid7}>
                    <div style={Style.grid7Child1}></div>
                    <div style={Style.grid7Child2}>
                        <h2 style={Style.pCenter1}>{content8}</h2>
                        <p style={Style.pCenter2}>{content9}</p>
                    </div>
                    <div style={Style.grid7Child3}></div>
                </section>
            }

            {
                (rule == "grid5C") &&

                <section style={Style.gridI}>
                    <div style={Style.gridIChild1}></div>
                    <div style={Style.gridIChild2}>
                        <h2 style={Style.pCenter1}>{content8}</h2>
                        <p style={Style.pCenter2}>{content9}</p>
                    </div>
                    <div style={Style.gridIChild3}></div>
                </section>
            }

        </>
    );
}

export default GridFlex;