import { useRouteError } from "react-router-dom"

const NotFoundPage = () => {
    const error = useRouteError()
    console.log(error)

    return (
        <>
            <div 
                style={{
                    color:"white",marginTop:"300px",
                    textAlign:"center",fontSize:"30px",
                }}
            >
                <h1>404 - Page Not Found</h1>
            </div>
        </>
    )

}

export default NotFoundPage