import React from "react";
import Style from "../Style/Style";


const Video = ({
    rule,src
}) => {
    return (
        <>
            {
                (rule == "video1") &&

                <video
                    autoPlay
                    loop
                    muted
                    style={Style.elementHeader1}
                >
                    <source src={src}
                    type={"video/mp4"}></source>
                </video>

            }

            {
                (rule == "video3") &&

                <video
                    autoPlay
                    loop
                    muted
                    style={Style.elementHeader3}
                >
                    <source src={src}
                    type={"video/mp4"}></source>
                </video>

            }
            
            {
                rule == "video2" &&
                <iframe  
                    width="100%" 
                    height="538" 
                    src="https://www.youtube.com/embed/J6KN55boqTY" 
                    title="Portfolio  Laravel ReactJs" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                        gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                >
                </iframe>
                
            }
        </>
    );
}

export default Video;